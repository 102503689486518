.scroller {
    margin-top: 100px;
}

.section {
    margin-top: 100px;
}

.topSection {
    margin-top: 50px;
}

@media (pointer: coarse) and (width < 1100px) {
    .topSection {
        margin-top: 30px;
    }

    .section {
        margin-top: 50px;
    }
}