.container {
    margin-left: 10%;
    width: fit-content;
    height: 100px;
    max-height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.text {
    font-weight: 600;
    color: white;
    font-size: 1.8em;
    margin-top: 0;
    margin-bottom: 0;
}

@media (width < 1000px) {
    .container {
        zoom: 90%;
    }
}

@media (pointer: coarse) and (width < 1000px) {
    .container {
        width: 100%;
        margin-left: 0;
    }
}