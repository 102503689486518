.dropdownButton {
    background-color: #ff7f27;
    padding: 9px 16px;
    color: white;
    border: none;
    font-size: 1.3rem;
}

.dropdown {
    background-color: #ff7f27;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%
}

.dropdownContainer {
    position: absolute;
}

.dropdownContent {
    position: absolute;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 100;
    margin-top: 42px;
}

.dropdownContent a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 1.3rem;
}

.intra {
    color: white;
    background-color: #4d4d4d;
}

.roy {
    color: white;
    background-color: #5a86a8;
}

.mbs {
    color: white;
    background-color: blue;
}

.bart {
    color: white;
    background-color: #30acdf;
}

.fossack {
    color: white;
    background-color: #003875;
}

.other {
    color: #7f7f7f;
    background-color: #ffc90e;
}

.news {
    color: white;
    background-color: #ff7f27;
}

.about {
    color: #7f7f7f;
    background-color: #ffc90e;
}

.display-none {
    display: none;
}