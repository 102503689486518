.container {
    height: 100%;
}

.search ul { 
    background: #dadada; 
    border-radius: 15px;
    width: 100%;
}

.search li {
    font-size: 1.0rem;
    margin-top: 10px;
}

@media (pointer: fine), (pointer: none) {
    /* desktop */
    .search input {
        height: 67px;
    }
}

@media (pointer: coarse) {
    /* mobile */
    .search input {
        height: 45px;
    }
}