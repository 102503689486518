.container {
    background-color: darkred;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
    width: fit-content;
    height: fit-content;
    margin-top: 10px;
}

.header {
    color: white;
    font-weight: 600;
    font-size: 1.7rem;
    margin: 0;
}

.text {
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.3;
    margin: 0;
}