@media (height < 800px) {
    .container {
        zoom: 85%
    }
}

.yellowFooter {
    background-color: #ffc90e;
    width: 100%;
    height: 15px;
}

.orangeFooter {
    background-color: #ff7f27;
    width: 100%;
    height: 45px;
}

.bottomLogo {
    max-width: 400px;
    width: 90%;
    margin-top: 3px;
}

@media (min-width: 900.5px) {
    .footerContents {
        display: flex;
        flex-direction: row;
        justify-content: left;
        flex-wrap: wrap;
        width: 100%;
    }

    .footerText {
        margin-left: auto;
        margin-right: auto;
        margin-top: 22px;
    }
}

@media (max-width: 900.5px) {
    .footerContents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
    }

    .footerText {
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }

    .bottomLogo {
        margin-left: auto;
        margin-right: auto;
    }
}

.footerText p {
    text-align: center;
    color: #7f7f7f;
}

.footerText1 {
    margin-top: 0;
}

.footerText2 {
    margin-top: -18px;
}

@media (pointer: coarse) {
    /* mobile */
    .footerText1, .footerText2 {
        padding-left: 5%;
        padding-right: 5%;
    }
}