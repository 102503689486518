.container {
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 1;
}

.num {
    position: absolute;
    z-index: 2;
    top: 37px;
    left: 25px;
}

.routeContainer {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 35px;
    border-radius: 60px;
    width: fit-content;
    z-index: 1;
    position: relative;
    max-width: 100%;
}

.bullet {
    height: 66px;
    margin-top: auto;
    margin-bottom: auto;
}

.routeName {
    color: white;
    font-size: 2.3rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
    line-height: 1.3;
}

.modeBox {
    margin-bottom: -20px;
    margin-left: 90px;
    z-index: 2;
    position: relative;
}