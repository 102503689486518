.container {
    position: relative;
    overflow-x: clip;
    width: 100%;
}

@media (height < 800px) {
    .container {
        zoom: 75%
    }

    .buttonContainer {
        zoom: 75%
    }
}

@media (pointer: fine), (pointer: none) {
    /* desktop */
    .container {
        height: 40vh;
        max-height: 500px;
        margin-top: 70px;
    }

    .buttonContainer {
        margin-top: 30px;
    }
}

@media (pointer: coarse) {
    /* mobile */
    .container {
        height: 525px;
        margin-top: 30px;
    }

    .buttonContainer {
        margin-top: 10px;
    }
}

.post {
    position: absolute;
    width: 100%;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: center;
}

.buttonHitBox {
    width: 85px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    width: 90%;
    height: 10px;
    border-radius: 10px;
    background-color: #e7e7e7;
}

.activeButton {
    background-color: #7f7f7f;
    height: 15px;
}

.pausePlay {
    height: 30px;
    
    /* https://codepen.io/sosuke/pen/Pjoqqp */
    filter: invert(55%) sepia(0%) saturate(37%) hue-rotate(161deg) brightness(91%) contrast(98%);
}