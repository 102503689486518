.bullet {
    height: 66px;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
}

.railNewBullet {
    text-align: center;
    width: 50px;
    position: absolute;
    margin-top: 17px;
    margin-left: 8px;
}

.container {
    height: 66px;
    width: 66px;
    position: relative;
}

.circle {
    height: 66px;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
}