.container {
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;
}

.images {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    animation: marquee 50s linear infinite;
    justify-content: space-around;
    gap: 75px;
}

.logo {
    height: 65px;
}

@keyframes marquee {
    0% {
        left: 0;
    }
    100% {
        left: 0%;
        transform: translateX(-100%);
    }
}