.container {
    height: 315px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    margin-top: 70px;
}

@media (pointer: coarse) {
    /* mobile */
    .container {
        margin-top: 30px;
    }
}

.liveFrame {
    padding-left: 10%;
}

.textBox {
    margin-left: 100px;
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text {
    color: white;
    font-weight: 600;
    margin: 0;
}

.circleBox {
    position: relative;
    height: fit-content;
    width: fit-content;
}

.circle {
    height: 40px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    margin-top: 3px;
}

.circleAnimated {
    position: absolute;
    height: 40px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    margin-top: 3px;
    top: 0;
}

.logo {
    height: 60px;
    margin-top: -6px;
    margin-left: 23px;
}

.header {
    font-size: 60px;
    margin-top: -20px;
    margin-left: 13px;
}

.descriptor {
    font-size: 35px;
    margin-top: -10px;
}

.video {
    height: 315px;
    aspect-ratio: 16 / 9;
}

.topLine {
    display: flex;
    flex-direction: row;
}

@media (pointer: coarse) and (width < 1000px) {
    .container {
        flex-direction: column;
        height: fit-content;
    }

    .video {
        height: unset;
        width: 100vw;
    }

    .textBox {
        zoom: 65%;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        padding-left: 5%;
        padding-right: 5%;
    }

    .liveFrame {
        padding-left: 0;
    }
}

@media (pointer: coarse) and (width > 1000px) and (width < 1500px) {
    .container {
        zoom: 75%;
    }
}