.line {
    width: 20px;
    margin-left: 43px;
    margin-top: -3px;
}

.lineContainer {
    display: flex;
    flex-direction: row;
    height: fit-content;
}

.nextToLine {
    width: fit-content;
    height: fit-content;
    margin-left: 30px;
}

.stopInLine {
    margin-bottom: 95px;
}

.stopCount {
    margin-bottom: 110px;
    font-weight: 600;
    font-size: 1.5rem;
}

.hidden {
    visibility: hidden;
}