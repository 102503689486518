.container {
    background-color: darkred;
    width: 83%;
    max-width: 800px;
    justify-content: left;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100px;
    padding: 50px;
}

.h1 {
    color: white;
    font-size: 3rem;
}

.p {
    color: white;
    font-size: 1.3rem;
}

.bold {
    font-weight: 600;
    background-color: white;
    color: darkred;
    padding-left: 5px;
    padding-right: 5px;
}

.h2 {
    color: white;
    font-size: 2.3rem;
    margin-bottom: 0;
}

.logo {
    height: 50px;
    margin-top: 20px;
}