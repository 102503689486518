.container {
    width: 100%;
    height: fit-content;
    position: relative;
    text-decoration: none;
}

.imgBox {
    width: 100%;
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.contentBox {
    width: 100%;
    height: 125px;
    bottom: 0;
    overflow: hidden;
    padding-left: 5%;
    padding-right: 5%;
}

.textContainer {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
}

.text {
    font-weight: 600;
    text-align: center;
}

.header {
    font-size: 38px;
    margin-top: 12px;
    margin-bottom: 0;
    line-height: 1.2;
}

.subHeader {
    font-size: 25px;
    margin-top: -4px;
    margin-bottom: 0;
}

.descriptor {
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 0;
}

@media (pointer: coarse) and (width < 1000px) {
    .contentBox {
        height: fit-content;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 45px;
        padding-top: 15px;
    }

    .header {
        font-size: 60px;
    }

    .subHeader {
        font-size: 35px;
    }

    .descriptor {
        font-size: 25px;
    }

    .imgBox {
        height: 300px;
    }
}

.button {
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
}

.buttonText {
    font-size: 40px;
    margin: 0;
}