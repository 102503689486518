.container {
    width: 90%;
    max-width: 1000px;
    background-color: darkred;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.text {
    color: white;
    font-weight: 600;
    margin: 0;
    line-height: 1.3;
    font-size: 20px;
}

.text2, .text3 {
    margin-top: 10px;
    font-weight: 400;
}