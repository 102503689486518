.stop {
    width: fit-content;
    max-width: 100%;
}

.firstStop {
    margin-top: 0px;
}

.transferStop{
    margin-top: 24px;
}

.segment {
    margin-top: -51px;
}

.container {
    max-width: 84%;
    margin-left: 8%;
}

@media (pointer: coarse) {
    /* mobile */
    .container {
        zoom: 75%;
    }
}