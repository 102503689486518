@media (pointer: fine), (pointer: none) {
    /* desktop */
    .logoBox {
        margin-top: 60px;
    }

    .search {
        margin-top: 60px;
        margin-bottom: 90px;
    }
}

@media (pointer: coarse) {
    /* mobile */
    .logoBox {
        margin-top: 40px;
    }

    .search {
        margin-top: 50px;
        margin-bottom: 40px;
    }
}

.logoBox {
    display: flex;
    justify-content: center;
}

@media (min-width: 500.5px) {
    .topLogo {
        height: 67px;
    }
}

@media (max-width: 500.5px) {
    .topLogo {
        width: 84%;
    }
}

.footer1 p, .footer2 p {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2px;
}

.pGray {
    color: #4d4d4d;
}

.footer1 {
    margin-top: 25px;
}

.footer2 {
    margin-top: 50px;
}

.footer1, .footer2 {
    padding-left: 5%;
    padding-right: 5%;
}

@media (pointer: fine), (pointer: none) {
    /* desktop */
    .bottomLogo {
        height: 67px;
    }
}

@media (pointer: coarse) {
    /* mobile */
    .footer1, .footer2 {
        zoom: 90%;
    }

    .bottomLogo {
        height: 50px;
    }
}

.buffer {
    width: 100%;
    height: 5px;
}