.container {
    display: flex;
    width: 100%;
}

@media (pointer: fine), (pointer: none) {
    /* desktop */
    .container {
        flex-direction: row;
    }

    .imgBox {
        width: 40%;
        height: 40vh;
    }

    .contentBox {
        width: 60%;
        height: 40vh;
    }
}

@media (pointer: coarse) {
    /* mobile */
    .container {
        flex-direction: column;
    }

    .imgBox, .contentBox {
        width: 100%;
        zoom: 75%;
    }

    .imgBox {
        height: 300px;
    }

    .contentBox {
        height: 400px;
        padding-bottom: 15px;
    }
}

.imgBox {
    max-height: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: white;
    overflow: hidden;
}

.img {
    object-fit: cover;
    margin: auto;
    position: relative;
}

.contentBox {
    max-height: 500px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.textContainer {
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.text {
    color: white;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
}

.header {
    font-size: 60px;
    margin: 0;
}

.descriptor {
    font-size: 35px;
    margin-top: 15px;
}

.button {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    border: 5px solid white;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 40px;
    border-radius: 40px;
}

.button:hover {
    background-color: white;
}

.buttonText {
    text-decoration: none;
    font-size: 2.2rem;
}

@media (width < 1000px) {

    .textContainer {
        zoom: 85%;
    }

}