.container {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: row;
}

.imgBox {
    height: 275px;
    width: 500px;
    background-color: white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentBox {
    height: 275px;
    width: 50%;
    min-width: 700px;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.text {
    font-weight: 600;
    margin: 0;
}

.header {
    font-size: 45px;
}

.descriptor {
    font-size: 27px;
}

.button {
    height: fit-content;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 40px;
    text-decoration: none;
    font-size: 30px;
    margin-top: 20px;
}

@media (width < 2000px) {
    .container {
        zoom: 85%;
    }
}

@media (width < 1500px) {
    .container {
        zoom: 65%;
    }
}

@media (width < 1100px) {
    .container {
        zoom: 75%;
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
    }

    .imgBox {
        width: 100%;
        height: 150px;
    }
    
    .contentBox {
        min-width: unset;
        max-width: unset;
        width: 100%;
        height: 300px;
        zoom: 75%;
    }
}