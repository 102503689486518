.section {
    margin-top: 70px;
}

.topSection {
    margin-top: 50px;
}

.sectionNoHeader {
    margin-top: 150px;
}

@media (pointer: coarse) and (width < 1100px) {
    .topSection {
        margin-top: 30px;
    }

    .section {
        margin-top: 50px;
    }
}