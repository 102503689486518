.stopContainer {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 35px;
    border-radius: 60px;
    margin-top: -3px;
    margin-left: 0px;
    max-width: 100%;
}

.stopInfo {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 17px;
}

.cityCode {
    margin-top: 5px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cityCodeNoStopName {
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    translate: 0px 17px;
}

.city {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 1.3;
    vertical-align: center;
}

.code {
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 1.3rem;
    margin-left: 5px;
}

.stopName {
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.3;
}

.metaContainer {
    margin-top: 15px;
    margin-bottom: 0px;
    margin-left: 98px;
    display: flex;
    flex-direction: row;
}

.meta {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 1.2rem;
}

.meta1, .meta2 {
    margin-top: 0px;
    margin-bottom: 0px;
    height: fit-content;
    width: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 30px;
}

.meta2 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
}