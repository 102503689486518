.video {
    height: 225px;
    margin: 0;
}

.contentBox {
    width: 400px;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.text {
    font-weight: 600;
    margin: 0;
}

.a {
    text-decoration: none;
}

.a:hover {
    text-decoration: underline;
}

.logo {
    height: 30px;
    margin-bottom: 20px;
}

.title {
    font-size: 30px;
    line-height: 1.2;
}

.date {
    font-size: 25px;
    font-weight: 400;
    margin-top: 40px;
}

@media (pointer: coarse) and (width < 1000px) {
    .video {
        height: unset;
        width: 100%;
    }

    .contentBox {
        width: 100%;
        zoom: 140%;
    }
}