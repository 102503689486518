.container {
    display: flex;
    flex-direction: row;
}

@media (pointer: fine), (pointer: none) {
    /* desktop */
    .box {
        height: 40px;
    }

    .title {
        margin-top: 1px;
        margin-bottom: 0px;
        margin-left: 10px;
        font-size: 1.6rem;
    }
}

@media (pointer: coarse) {
    /* mobile */
    .box {
        height: 35px;
    }

    .title {
        margin-top: 3px;
        margin-bottom: 0px;
        margin-left: 10px;
        font-size: 1.3rem;
    }
}


.modeBox {
    margin-left: 10px;
}

.title {
    font-weight: 600;
    color: #4d4d4d;
    user-select: none;
}