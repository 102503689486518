nav {
    background-color: #ff7f27;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.button {
    margin: auto 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.button a {
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
}

.intra:has(a:hover) {
    background-color: #4d4d4d;
}

.roy:has(a:hover) {
    background-color: #5a86a8;
}

.mbs:has(a:hover) {
    background-color: blue;
}

.bart:has(a:hover) {
    background-color: #30acdf;
}

.fossack:has(a:hover) {
    background-color: #003875;
}

.other:has(a:hover), .news:has(a:hover), .about:has(a:hover) {
    background-color: #ffc90e;
}

.other a:hover, .news a:hover, .about a:hover {
    color: #7f7f7f;
}