.modeBox {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 17px;
    padding-right: 17px;
    border-radius: 23px;
}

.intraBox {
    background-color: #4d4d4d;
}

.omegaBox {
    background-color: #7f0000;
}

.segvilleBox {
    background-color: #f15a24;
}

.waypointBox {
    background-color: #041c21;
}

.waypointHopperBox {
    background-color: #e16326;
}

.volantiBox {
    background-color: #be210c;
}

.skywestBox {
    background-color: #414141;
}

.gemsBox {
    background-color: #ffffff;
}

.heampsteadBox {
    background-color: #ce3d00;
}

.easternBox {
    background-color: #f0731f;
}

.mcrBox {
    background-color: #1300fe;
}

.intraLogos {
    height: 23px;
}

.omegaLogo {
    height: 23px;
}

.segvilleLogos {
    height: 23px;
}

.waypointLogo {
    height: 23px;
}

.volantiLogo {
    height: 23px;
}

.skywestLogo {
    height: 23px;
}

.gemsLogo {
    height: 23px;
}

.heampsteadLogo {
    height: 23px;
}

.easternLogo {
    height: 23px;
}

.mcrLogo {
    height: 23px;
}

.railScarLogo {
    height: 23px;
}

.railLumevaLogo {
    height: 23px;
}

.whiteOutline {
    outline: 6px solid white;
}