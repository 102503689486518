.container {
    width: 83%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.labelContainer {
    display: flex;
    flex-direction: row;
    width: fit-content;
}

@media (pointer: fine), (pointer: none) {
    /* desktop */
    .label {
        font-size: 1.3rem;
    }

    .arrowRight, .arrowDown {
        margin-left: 10px;
    }
}

@media (pointer: coarse) {
    /* mobile */
    .label {
        font-size: 1.2rem;
    }

    .arrowRight, .arrowDown {
        height: 14px;
        margin-top: 7px;
        margin-left: 7px;
    }
}

.label {
    color: #4d4d4d;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 0px;
    user-select: none;
}

.arrowRight {
    transform: rotate(-90deg)
}