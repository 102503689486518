@media (height < 800px) {
    .container {
        zoom: 85%
    }
}

.topLogo {
    max-width: 400px;
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 13px;
    padding-top: 10px;
}

.yellowHeader {
    background-color: #ffc90e;
    width: 100%;
    height: 15px;
}